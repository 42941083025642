

window.addEventListener('click', (event) => {

    // navbar show profile auto close logic
    if(document.getElementById("navbar-profile-icon").contains(event.target)){
      // do nothing
    } else if(document.getElementById("profile-icon-checkbox").contains(event.target)){
      // do nothing
    } else {
      if(document.getElementById("profile-icon-checkbox").checked == true){
        document.getElementById("profile-icon-checkbox").checked = false;
      }
    }


    // dashboard context menu auto close logic
    if(event.target.className == "dash-context-menu-cont" || event.target.className == "dash-context-menu-icon" || event.target.className == "dash-context-menu-wrapper"){
    } else{
       // close previous opened popup menu
      let allElements = document.getElementsByClassName("dash-context-menu-cont");
      for (let i = 0; i < allElements.length; i++) {
          allElements.item(i).setAttribute("style", "display: none");
      }
    }

    // default filters in dashboard auto close 
    if(event.target.closest(".default-filter-content") == null){
      if(event.target.className.includes("default-filter-container") || event.target.className.includes("default-filter-arrow-icon") || event.target.className.includes("close-buble-icon")){
        // do nothig
      } 
      else {
        // close default filter popup content
        let allElements = document.getElementsByClassName("default-filter-content");
        for (let i = 0; i < allElements.length; i++) {
            allElements.item(i).setAttribute("style", "display: none");
        }
        // rotate all icon
        let defaultFilterArrowIconsList = document.getElementsByClassName("default-filter-arrow-icon");
        Array.prototype.forEach.call(defaultFilterArrowIconsList, function(item) {
          item.style.transform = "rotate(0deg)";
        });
      }
    }

  });